.infoContainer {
  display: grid;
  grid-template-columns: minmax(200px, auto) 1fr;
  grid-template-rows: repeat(6, auto) 1fr;
  justify-content: start;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: var(--white);

  .title {
    grid-column: span 2;
    margin-bottom: 16px;
    color: var(--grey-semi-dark);
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    &.participantsTitle {
      margin-top: 24px;
    }
  }

  .pr16 {
    padding-right: 16px;
  }

  .pb16 {
    padding-bottom: 26px;
  }
}
