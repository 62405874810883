.contentDescription,
.contentSteps {
  font-weight: 400;
  color: var(--grey-semi-dark);
  padding: 16px;
  letter-spacing: 1.5px;

  & a {
    color: var(--grey-semi-dark);
  }
}

.contentSteps,
.setupHeader {
  text-transform: uppercase;
}

.contentTxt {
  font-weight: 400;
  color: var(--black);
  padding: 0px 14px 14px 16px;
  max-width: 680px;
}

.setupHeader {
  font-weight: 700;
  font-size: var(--twenty-pixels-font-size);
  padding-top: 28px;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--white);

  &:first-of-type {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

.fieldRow {
  padding: 16px 16px 16px 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.contentTxt,
.contentDescription,
.typography {
  font-size: 14px;
}

.helperText {
  color: var(--hot-cinnamon);
}

.inputFieldValue {
  margin: 8px;
  color: var(--input-field-value);
}
