.tooltipWrapper {
  display: flex;
}

.divider {
  border-color: rgba(255, 255, 255, 0.3);
  margin-right: 15px;
}

.classroomInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.classroomName {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}

.studentCount {
  font-size: 8px;
  font-weight: normal;
}
