.listContainer {
  display: flex;
  flex-grow: 1;
  gap: 16px;
  overflow: auto;
  padding-top: 16px;
}

.panelContainer {
  display: grid;
  grid-template-rows: max-content 1fr;
  flex: 1 0 528px;
  background-color: var(--white);
  height: 100%;
  overflow: auto;

  @media only screen and (min-width: 1680px) {
    flex-basis: 720px;
  }

  @media only screen and (min-width: 1920px) {
    flex-basis: 848px;
  }
}

.panelLabel {
  padding: 8px 16px 0;
}

.unActive {
  opacity: 0.6;
}
