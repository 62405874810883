.teacherNav {
  display: inline-block;
  background-color: var(--teacher-nav-background-color);
  width: var(--teacher-nav-width);

  .classroomsContainer {
    max-height: 65%;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .classroomsContainer::-webkit-scrollbar {
    width: 5px;
  }

  .classroomsContainer::-webkit-scrollbar-track {
    background: transparent;
  }

  .classroomsContainer::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .classroomsContainer:hover::-webkit-scrollbar-thumb {
    background: var(--grey-medium);
  }

  a {
    text-align: center;
  }

  .topNav {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin-top: 24px;
    }
  }

  .bottomNav {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;

    a {
      margin-bottom: 24px;
    }

    @media (min-height: 640px) {
      height: 20%;
    }
  }

  .separator {
    margin-top: 24px;
    display: inline-block;
    width: var(--separator-width);
    height: 2px;
    background: #ffffff60;
    margin-left: calc(
      (#{var(--nav-icon-background-width)} - #{var(--separator-width)}) / 2
    );
  }

  .sideBarIcon {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .iconBackground {
    width: var(--nav-icon-background-width);
    height: var(--nav-icon-background-width);
    background: var(--nav-icon-background-color);
    line-height: 55px;

    img.iconProfile {
      width: inherit;
      background: none;
      border-radius: 50%;
      opacity: 1;
      max-height: 42px;
      overflow: hidden;
    }
  }

  .activeBackground {
    @extend .iconBackground;
    background: var(--green-primary);

    img {
      opacity: 1;
    }
  }

  .profileIconBackground {
    @extend .iconBackground;
    background: none;
  }

  .classroomIcon {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    width: var(--nav-icon-background-width);
    height: var(--nav-icon-background-width);
    display: inline-block;
    line-height: var(--nav-icon-background-width);

    &:hover {
      color: var(--classroom-hover-active-color);
    }
  }

  .activeClassroomIcon {
    @extend .classroomIcon;
    background: var(--green-primary);
    color: var(--classroom-hover-active-color);
  }

  .badge {
    top: 30px;
    height: 16px;
    right: 10px;
    font-size: 9px;
    min-width: 16px;
    border-radius: 4px;
  }
}
