.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentWrapper {
  background: var(--white);
  padding: 16px;

  .title {
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: var(--grey-semi-dark);
  }

  .description {
    display: flex;
    align-items: center;
    color: var(--mine-shaft);
  }

  .domainsList {
    margin-bottom: 14px;
    font-weight: 500;
  }
}
