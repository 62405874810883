.fileForReview {
  color: var(--white);
  font-size: 9px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  background-color: var(--green-primary);
  border-radius: 4px;
  margin-left: auto;
  height: max-content;
  padding: 3px 4px 2px;
  text-transform: uppercase;
}
