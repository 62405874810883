.accordionSummary {
}

.accordionExpanded {
  margin: 12px 0 !important;
}

.accordionSummaryExpanded {
  align-items: center;
  min-height: 48px !important;
}

.summaryContent {
  align-items: center;
  margin: 0 !important;
}

.accordionDetails {
  padding-top: 0;
  padding-bottom: 0;
}

.studentAvatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.studentName {
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.documentRow {
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr max-content;
  padding: 8px 8px;

  .fileImageContainer {
    display: flex;
    align-items: center;
    position: relative;

    .fileIcon {
      width: 22px;
      height: 22px;
    }

    .descriptionIcon {
      color: var(--royal-blue);
    }
  }

  .fileInfoContainer {
    margin-left: 12px;
    display: flex;
    flex-direction: column;

    .fileName {
      display: inline-block;
      color: var(--charade);
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      letter-spacing: 0.25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .fileModifiedTime {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.4px;
      line-height: 16px;
      color: var(--grey-semi-dark);
    }
  }
}
